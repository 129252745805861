<template>
  <div>
    <form @submit="onSubmit">
      <template v-if="!appliedGiftCard.length">
        <button
          class="flex items-center justify-between w-full [ text-primary-1-100 ]"
          :class="{ 'mb-10': toggleGiftCard }"
          type="button"
          @click="toggleGiftCard = !toggleGiftCard"
        >
          <svg-icon-gift-card width="24" height="24" class="mr-2 text-primary-1-100" />
          {{ $t('haveAGiftCard') }}

          <div
            class="checkbox ml-auto flex justify-center items-center p-1 rounded-md"
            :class="{
              active: toggleGiftCard || appliedGiftCard.length,
            }"
          >
            <svg-icon-check
              :class="{ invisible: !toggleGiftCard, 'text-white': toggleGiftCard }"
              class="stroke-current"
            />
          </div>
        </button>
      </template>
      <GiftCard
        v-if="toggleGiftCard || appliedGiftCard.length"
        :applied-gift-card="appliedGiftCard"
        :loading="loading"
        class="block"
        @remove="removeGiftCardFromCart"
      >
        <template #cancel>
          <button
            type="button"
            class="text-system-red font-body underline ml-5"
            @click="toggleGiftCard = !toggleGiftCard"
          >
            {{ $t('cancel') }}
          </button>
        </template>
      </GiftCard>
    </form>
  </div>
</template>

<script setup lang="ts">
import { object, string } from 'yup';
import { useExtendedCartAttributes } from '~/composables/cart';

defineComponent({
  name: 'CheckoutGiftCard',
});

const { t: $t } = useI18n({
  useScope: 'local',
});
const { t: _$t } = useI18n({ useScope: 'global' });

const { handleSubmit, setErrors, resetForm } = useForm({
  validationSchema: toTypedSchema(
    object({
      code: string().required(),
    }),
  ),
});

const { appliedGiftCard: cartGiftCards } = useExtendedCartAttributes();
const { isFetching: isApplyingGiftCard, applyGiftCard } = useApplyGiftCard();
const { isFetching: isRemovingGiftCard, removeGiftCard } = useRemoveGiftCard();
const { error } = useAlerts();

const toggleGiftCard = ref<boolean>(false);
const emit = defineEmits(['changePaymentMethods', 'isOpen']);

const onSubmit = handleSubmit(async ({ code }) => {
  try {
    const { giftCardPaymentMethods } = await applyGiftCard(code);
    emit('changePaymentMethods', giftCardPaymentMethods.value);
    toggleGiftCard.value = false;
    resetForm();
  } catch (e) {
    setErrors({ code: $t((e as Error).message).toString() });
  }
});

async function removeGiftCardFromCart(code: string) {
  try {
    const { giftCardPaymentMethods } = await removeGiftCard(code);
    emit('changePaymentMethods', giftCardPaymentMethods.value);
  } catch (e) {
    error(_$t('cartError').toString(), _$t((e as Error).message).toString());
  }
}

watch(
  toggleGiftCard,
  newValue => {
    emit('isOpen', newValue);
  },
  {
    immediate: true,
  },
);

const loading = computed(() => isApplyingGiftCard.value || isRemovingGiftCard.value);
const appliedGiftCard = computed(() => (cartGiftCards?.value ? [cartGiftCards.value] : []));
</script>

<style lang="postcss" scoped>
.checkbox {
  @apply h-6 w-6  border border-primary-1-100 mr-2;

  &.active {
    @apply bg-primary-1-100  border-white;
  }
}
</style>

<i18n>
  {
	  "en": {
	  "haveAGiftCard": "Redeem Gift Card",
	  "cancel": "Cancel",
	  "giftCardApplied": "Gift Card Applied",
	  "giftCardRemoved": "Gift Card Removed",
	  "invalidGiftCard": "The digital card code you’ve entered is incorrect.",
	  "giftCardNoBalance": "Unfortunately your gift card balance is 0.00 EGP",
	  "usedGiftCard": "This Gift Card is already in the Quote",
	  "giftCardNotActive": "Gift Card is not activated"
	  },
	  "ar": {
	  "haveAGiftCard": "استعمال بطاقة الهدايا",
	  "cancel": "الغاء",
	  "giftCardApplied": "تم استعمال بطاقه الهدايا",
	  "giftCardRemoved": "تم ازالة بطاقة الهدايا",
	  "invalidGiftCard": "رقم البطاقة الرقمية الذي أدخلته غير صحيح",
	  "giftCardNoBalance": "رصيد بطاقة الهدايا الخاصة بك هو 0.00 جنيه مصري",
	  "usedGiftCard": "بطاقة الهدايا هذه مستعمله بالفعل",
	  "giftCardNotActive": "بطاقه الهدايا غير مفعله"
	  }
  }
  </i18n>
