<template>
  <div>
    <TextInput
      v-if="!(single && appliedCoupons.length)"
      id="code"
      v-model="code"
      name="code"
      :label="$t('coupon')"
      type="text"
      class="text-primary-1-100"
      autocomplete="code"
      rounded
      filled
      border
    >
    </TextInput>

    <template v-if="appliedCoupons && appliedCoupons.length">
      <div v-for="(coupon, index) in appliedCoupons" :key="index" class="flex justify-between items-center w-full p-5">
        <span class="text-primary-1-100 font-medium [ flex items-center justify-start ]">
          <svg-icon-like width="20" height="20" class="mr-2" />
          {{ $t('promoCodeApplied', { code: coupon }) }}
        </span>

        <Spinner v-if="loading" class="w-6 h-6 mx-auto" />

        <button
          v-else
          aria-label="clear coupon"
          type="button"
          class="ml-auto hidden md:flex p-2 md:p-2 bg-system-red-light border border-system-red-light rounded-xs [ items-center justify-center ]"
          @click="$emit('remove', coupon || '')"
        >
          <svg-icon-trash width="20" height="20" />
        </button>
      </div>
    </template>

    <div v-if="!appliedCoupons.length" class="mt-6 flex items-center">
      <AppButton
        type="submit"
        :with-arrow="false"
        class="[ flex items-center justify-center ]"
        :disabled="!code || loading"
        inverted
      >
        <Spinner v-if="loading" class="text-white w-6 h-6 mx-auto" />
        <template v-else>
          <span class="text-white"> {{ $t('applyCoupon') }} </span>
        </template>
      </AppButton>

      <slot name="cancel" />
    </div>
  </div>
</template>
<script setup lang="ts">
defineComponent({
  name: 'Coupons',
});

const { t: $t } = useI18n({
  useScope: 'local',
});

defineProps({
  appliedCoupons: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  single: {
    type: Boolean,
    default: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits<{
  (e: 'submit', code: string | undefined): void;
  (e: 'remove', code: string | undefined): void;
}>();

const code = ref<string>('');

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function applyCoupon() {
  emit('submit', code.value);
}
</script>
<i18n>
{
  "en": {
    "code": "Code",
    "applyCoupon": "Apply PromoCode",
    "promoCodeApplied": "Promocode successfully applied {code}",
    "coupon": "Coupon"
  },
  "ar": {
    "code": "الكود",
    "applyCoupon": "تطبيق الكود",
    "promoCodeApplied": "تم تطبيق الكود بنجاح {code}",
    "coupon": "كوبون"
  }
}
</i18n>
